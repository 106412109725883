<template>
  <v-container>
    <v-data-table
      calculate-widths
      :headers="header_orders[$vuetify.breakpoint.xsOnly]"
      :items="orders"
      :items-per-page="15"
      item-key="index"
      sort-by="issue_date"
      sort-desc
      mobile-breakpoint="0"
      class="table-cursor elevation-2"
      loading-text="Cargando ..."
      @click:row="view_doc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> Documentos </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="pa-2">
          <v-col>
            <v-select
              v-model="doc_type"
              hide-details="auto"
              item-text="label"
              item-value="value"
              :items="docType"
              label="Documento"
              outlined
              @change="get_oders()"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="issueDateFrom"
              label="Desde"
              hide-details="auto"
              required
              outlined
              type="date"
              @change="get_oders()"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="issueDateTo"
              label="Hasta"
              hide-details="auto"
              required
              outlined
              type="date"
              @change="get_oders()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.delivery_date`]="{ item }" class="text-right">
        {{ item.delivery_date }}<br />
        <small>{{ item.delivery_time }}</small>
      </template>
      <template v-slot:[`item.total`]="{ item }" class="text-right">
        {{ "$ " + parseFloat(item.total).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="view_doc(item)"> mdi-magnify </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { webserver, getToday, getIssueDate } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import docs_lst from "../utils/docs.js";

export default {
  components: {},
  mounted() {
    this.company = this.$store.getters.company;
    //var params = this.$route.params;
    //console.log(params);
    this.get_oders();
  },
  data: () => ({
    categories: [],
    doc_type: null,
    docType: docs_lst().docType,
    items: [
      {
        id: 3,
        name: "Mis pedidos",
        description: "Creear, editar o eliminar pedidos.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
      {
        id: 1,
        name: "Lista de Precios",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "warning",
        to: "Proveedores",
      },
      {
        id: 2,
        name: "Proveedores",
        description: "Creear, editar o eliminar proveedores.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },

      {
        id: 4,
        name: "Mi perfil",
        description: "Editar perfil, logo, información de contacto.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
    ],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    header_orders: {
      false: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Orden",
          align: "start",
          value: "doc_code",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Entregar",
          align: "start",
          value: "delivery_date",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
      true: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
    },
    issueDateFrom: getIssueDate(60),
    issueDateTo: getToday(),
  }),
  methods: {
    get_oders() {
      var qry = {
        table: "docs",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "store", operator: "=", value: this.company.code },
          { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
          { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
        ],
      };
      if (this.doc_type) {
        qry.filters.push({ field: "doc_type", operator: "=", value: this.doc_type });
      }
      //console.log(qry, this.$store.state.company);
      webserver("get_doc_status", qry, (data) => {
        //console.log(data);
        this.orders = data;
      });
    },
    view_doc(e) {
      // //console.log(e);
      // if (e.doc_type == 'OR') {
      this.$router.push({ name: "Doc_e", params: { did: e.doc_code } });
      // } else  {
      //   this.$router.push({ name: "Doc", params: { did: e.doc_code } });
      // }
    },
  },

  watch: {},
};
</script>

<style></style>
